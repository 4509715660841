const POST = 'POST';
const PUT = 'PUT';
const GET = 'GET';
const DELETE = 'DELETE';

const ACTIVE_ICON_CLASS = "active-icon";
const DEFAULT_ICON_CLASS = "default-icon";

const SERVER_ADDRESS = "https://api.hyperloop.biz";
// const SERVER_ADDRESS = "http://localhost:5656";
const WHOAMI_URL = "/_a/whoami";
import { Chart } from "./../static/chart/chart";
export const ACCESS_TOKEN_KEY = "access_token";
export const DEMO_WORKSPACE_ID = 1;
export const DASHBOARD_URL = "/dashboard";
export const LOGIN_URL = "/login";
export const USER_URL = "/_a/user";
export const WORKSPACE_URL = "/_a/workspace";
export const WORKSPACES_URL = "/_a/workspaces";

export const HEADER_COMPONENT_URL = "/header.html";
export const ROLES = Object.freeze([
    "Owner",
    "Admin",
    "Editor",
    "Viewer"
]);

export const DEFAULT_ROLE = "Viewer"

const addServerAddressToUrl = (url: string | URL) => {
    return SERVER_ADDRESS + url;
}

export class ResponseClass {
    response?: any;
    status?: number;
    constructor(_response?: any, _status?: number) {
        this.response = _response;
        this.status = _status;
    }
}

export class WhoamiClass extends ResponseClass {
    Authorization?: string | undefined;
    isLoggedIn?: boolean;
    user?: any;
}

export class ChartClass { 
    id?: string;
    data?: any;
    chart_label?: string; 
    axis_labels?: any; 
    is_pinned?: any;
    query_id?: any; 
    view_id?: any;
}

export async function isUserLoggedIn() {
    const result: WhoamiClass = {
        isLoggedIn: false,
        user: null
    };
    const headers = getAuthHeaders();
    if (Object.keys(headers).length !== 0) {
        try {
            let response = await httpGetRequestWithHeaders(WHOAMI_URL, headers, {}) as WhoamiClass;
            if (response.status === 200) {
                let data = JSON.parse(response.response);
                if (data.success) {
                    result.isLoggedIn = true;
                    result.user = data.payload;
                    return result;
                }
                return result;
            }
            return result;
        } catch (err) {
            console.error(err);
            return result;
        }
    }
    return result;
}

export const getAuthHeaders = () => {
    const token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
    return {
        Authorization: token === null? undefined : `Bearer ${token}`
    } as WhoamiClass
}

export const saveTokenToLocalStorage = (token: string) => {
    window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export const removeTokenFromLocalStorage = () => {
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
}

export const redirectToLogin = () => {
    window.location.href = LOGIN_URL;
}

export async function load(url: RequestInfo, element: { innerHTML: string; }) {
    const resp = await fetch(url);
    const header = await resp.text();
    element.innerHTML = header;
}

export const httpGetRequest = (url: string | URL, params: { id?: string; },  callback: (response: ResponseClass) => void) => {
    url = getUrlWithParams(url, params);
    const http = new XMLHttpRequest();
    http.open(GET, url, true);
    http.send(null);
    http.onreadystatechange = function() {
        if (http.readyState === http.DONE) {
            callback(new ResponseClass(http.responseText, http.status));
        }
    };
}

export async function asyncHttpGetRequest(url: string | URL, params: any) {
    url = getUrlWithParams(url, params);
    return new Promise(function(resolve, reject) {
        const http = new XMLHttpRequest();
        http.open(GET, url, true);
        http.onload = function() {
            if (this.status >= 200 && this.status < 300) {
                resolve({ response: http.responseText, status: http.status });
            } else {
                reject({ response: http.statusText, status: http.status });
            }
        }
        http.send(null);
    })
}

export async function httpGetRequestWithHeaders(url: string | URL, headers: WhoamiClass, params: { query?: any; }) {
    url = getUrlWithParams(url, params);
    return new Promise(function(resolve, reject) {
        const http = new XMLHttpRequest();
        http.open(GET, url, true);
        for (let key in headers) {
            http.setRequestHeader(key, headers[(key as keyof WhoamiClass)]);
        }
        http.withCredentials = true;
        http.onload = function() {
            if (this.status >= 200 && this.status < 300) {
                resolve({ response: http.responseText, status: http.status });
            } else {
                reject({ response: http.statusText, status: http.status });
            }
        }
        http.send(null);
    })
}

export const httpPostRequest = (url: string | URL, body: any, callback: (response: ResponseClass) => void) => {
    url = addServerAddressToUrl(url);
    const headers = getAuthHeaders();
    const http = new XMLHttpRequest();
    http.open(POST, url, true);
    for (let key in headers) {
        http.setRequestHeader(key, headers[(key as keyof WhoamiClass)]);
    }
    http.send(JSON.stringify(body));
    http.onreadystatechange = function() {
        if (http.readyState === http.DONE) {
            callback(new ResponseClass(http.responseText, http.status));
        }
    };
}

export const httpPutRequest = (url: string | URL, 
    body: { client_config?: { active_workspace_id: any; } | { active_workspace_id: any; }; workspace_id?: number; username?: any; role?: any; name?: any; }, 
    callback: (response: ResponseClass) => void) => {
    url = addServerAddressToUrl(url);
    const headers = getAuthHeaders();
    const http = new XMLHttpRequest();
    http.open(PUT, url, true);
    for (let key in headers) {
        http.setRequestHeader(key, headers[(key as keyof WhoamiClass)]);
    }
    http.send(JSON.stringify(body));
    http.onreadystatechange = function() {
        if (http.readyState === http.DONE) {
            callback(new ResponseClass(http.responseText));
        }
    };
}

export const httpDeleteRequest = (url: string | URL, callback: (response: ResponseClass) => void) => {
    url = addServerAddressToUrl(url);
    const headers = getAuthHeaders();
    const http = new XMLHttpRequest();
    http.open(DELETE, url, true);
    for (let key in headers) {
        http.setRequestHeader(key, headers[(key as keyof WhoamiClass)]);
    }
    http.send(null);
    http.onreadystatechange = function() {
        if (http.readyState === http.DONE) {
            callback(new ResponseClass(http.responseText));
        }
    };
}

const getUrlWithParams = (url: string | URL, params: string | URLSearchParams | string[][] | Record<string, string> | undefined) => {
    const searchParams = new URLSearchParams(params);
    if (searchParams.toString() !== "") {
        url = url + '?' + searchParams;
    }
    return addServerAddressToUrl(url);
}

export const getWorkspaceListElement = (workspace: { id: any; name: any; role: any; }, handler: { (workspaceId: any): void; (arg0: any): any; }) => {
    const li = document.createElement("li");
    const a = document.createElement("a");
    a.classList.add("dropdown-item");
    a.addEventListener("click", () => handler(workspace.id));
    a.innerText = `${workspace.name} (${workspace.role})`;
    li.appendChild(a);
    return li;
}

export const getSentenceNode = (data: { sentence: string; }) => {
    const para = document.createElement("p");
    para.innerHTML = data.sentence;
    return para;
}

export const getTableNode = (tableData: { columns: any[]; data: any[]; }) => {
    const tdiv = document.createElement("div");
    const table = document.createElement("table");
    const thead = document.createElement("thead");
    const tbody = document.createElement("tbody");
    // creating empty table
    table.classList.add("table", "table-sm", "table-hover", "table-bordered")
    table.append(thead, tbody);
    // creating head row
    const hrow = document.createElement("tr");
    tableData.columns.forEach((col: string) => {
        const hcell = document.createElement("td");
        hcell.innerHTML = col;
        hrow.appendChild(hcell)
    })
    thead.appendChild(hrow);
    // creating table body
    tableData.data.forEach((rowData: any[]) => {
        const trow = document.createElement("tr");
        rowData.forEach((val: string) => {
            const tcell = document.createElement("td");
            tcell.innerHTML = val;
            trow.appendChild(tcell);
        });
        tbody.appendChild(trow)
    });
    tdiv.classList.add("col-6");
    tdiv.appendChild(table);
    return tdiv;
}

export const getChartNode = (chartData: ChartClass, pinResult: (queryId: any, viewId: any, chartCtx: any) => void, unpinResult: (chartCtx: any) => void, isBody: boolean) => {
    const chartOptions = getBarChartOptions(chartData.data, chartData.chart_label, chartData.axis_labels);
    // creating a canvas for chart
    const chartCtx = document.createElement("canvas");
    // adding chart to canvas
    new Chart(chartCtx, chartOptions)

    // create card for canvas
    const card = document.createElement("div")
    card.classList.add("card", "mt-2");
    card.appendChild(chartCtx); // adding chart to card

    // Card body
    const cardBody = document.createElement("div");
    cardBody.classList.add("card-body", "card-content")

    // Card Title
    const cardTitle = document.createElement("h6");
    cardTitle.classList.add("card-title", "fw-light");
    cardTitle.innerHTML = chartData.chart_label || "";

    // Card Actions
    const action = document.createElement("span");
    action.classList.add("badge", "cursor-pointer");
    action.innerHTML = `<i class="fas fa-thumbtack fa-lg tilt-50"></i>`;
    if (chartData.is_pinned) {
        action.classList.add(ACTIVE_ICON_CLASS);
        chartCtx.id = chartData.id || '';
    } else {
        action.classList.add(DEFAULT_ICON_CLASS);
    }
    action.onclick = (function(queryId, viewId) {
        const data = chartData; // variable shadowing
        return function () {
            if (data.is_pinned) {
                unpinResult(chartCtx);
                action.classList.remove(ACTIVE_ICON_CLASS);
                action.classList.add(DEFAULT_ICON_CLASS);
                data.is_pinned = false;
            } else {
                pinResult(queryId, viewId, chartCtx);
                action.classList.add(ACTIVE_ICON_CLASS);
                action.classList.remove(DEFAULT_ICON_CLASS);
                data.is_pinned = true;
            }
        }
    })(chartData.query_id, chartData.view_id);

    cardBody.appendChild(cardTitle);
    cardBody.appendChild(action);
    if (isBody) {
        card.appendChild(cardBody);
    }
    return card;
}

const chartBackgroundColors = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(40, 82, 122, 0.2)',
    'rgba(255, 204, 41, 0.2)'
];
const chartBorderColors = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(40, 82, 122, 1)',
    'rgba(255, 204, 41, 1)'
];

export const getBarChartOptions = (data: any, chartLabel: any, axisLabels: any) => {
    return {
        type: 'bar',
        data: {
            labels: axisLabels,
            datasets: [{
                label: chartLabel,
                data: data,
                backgroundColor: chartBackgroundColors,
                borderColor: chartBorderColors,
                borderWidth: 1,
                barPercentage: 0.4
            }]
        },
        options: {
            plugins: {
                tooltip: {
                    // to add custom tooltip
                }
            },
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        }
    }
}

export const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export const validatePassword = (password: string | any[]) => {
    // TODO: Uncomment alpha numeric with special character password logic

    // const regex = /^[a-zA-Z0-9@\\\\#$%&*()_+\\]\\[';:?.,!^-]{8,}$/
    // return regex.test(password);

    // temporary for testing purposes
    return password.length > 5;
}

export async function getWorkspaceById(workspaceId: number) {
    const headers = getAuthHeaders();
    const url = `${WORKSPACE_URL}/${workspaceId}`;
    const res: any = await httpGetRequestWithHeaders(url, headers, {});
    if (res.status === 200 && res.response !== "") {
        const response = res.response;
        let data = JSON.parse(response);
        if (data.success && data.payload) {
            return data.payload;
        }
    }
    return null;
}

export async function getAllWorkspaces() {
    const headers = getAuthHeaders();
    const res: any = await httpGetRequestWithHeaders(WORKSPACES_URL, headers, {});
    if (res.status === 200 && res.response !== "") {
        const response = res.response;
        let data = JSON.parse(response);
        if (data.success && data.payload) {
            return data.payload;
        }
    }
    return [];
}

export async function getWorkspaceMemberDetails(workspaceId: any) {
    const headers = getAuthHeaders();
    const url = `${WORKSPACE_URL}/${workspaceId}/member`;
    const res: any = await httpGetRequestWithHeaders(url, headers, {});
    if (res.status === 200 && res.response !== "") {
        const response = res.response;
        let data = JSON.parse(response);
        if (data.success && data.payload) {
            return data.payload;
        }
    }
}

export function timeDifference(current: any, previous: any) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
    var elapsed = current - previous;

    if (elapsed < msPerMinute) return Math.round(elapsed/1000) + ' seconds ago';
    else if (elapsed < msPerHour) return Math.round(elapsed/msPerMinute) + ' minutes ago';
    else if (elapsed < msPerDay ) return Math.round(elapsed/msPerHour ) + ' hours ago';
    else if (elapsed < msPerMonth) return Math.round(elapsed/msPerDay) + ' days ago';
    else if (elapsed < msPerYear) return Math.round(elapsed/msPerMonth) + ' months ago';
    else return Math.round(elapsed/msPerYear ) + ' years ago';
}